/* global SC */
import React from 'react'
import Script from 'react-load-script'
import moment from 'moment'
import Img from 'gatsby-image'
import ContentTrackListing from '../ContentTrackListing'
import { Box, Text } from 'rebass'
import { Artwork, Player, Ctrl, Play, Pause, BuyLink } from './styles'

class ReleaseArtwork extends React.PureComponent {
  constructor (props) {
    super(props)
    this.soundcloud = null
    this.play = this.play.bind(this)
    this.state = {
      scriptLoaded: false,
      isSoundcloudReady: false,
      isSoundcloudPlaying: false
    }
  }

  componentDidMount () {
    this.setIframeRef = element => {
      this.iframe = element
      this.handleScriptLoad()
    }
  }

  handleScriptCreate () {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError () {
    this.setState({ scriptError: true })
  }

  handleScriptLoad () {
    this.setState({ scriptLoaded: true })
    if (this.iframe) {
      this.soundcloud = SC.Widget(this.iframe)
      this.soundcloud.bind(SC.Widget.Events.READY, () => {
        this.setState({ isSoundcloudReady: true })
      })
    }
  }

  play () {
    if (this.props.soundcloudid) {
      this.setState({ isSoundcloudPlaying: !this.state.isSoundcloudPlaying })
      this.soundcloud.setVolume(40)
      this.soundcloud.toggle()
    }
  }

  render () {
    const artwork = this.props.artwork ? {
      alt: `${this.props.title} - ${this.props.artist}`,
      sizes: this.props.artwork.localFile.childImageSharp.sizes
    } : { }
    const isReleased = (moment(this.props.releasedate, 'DD/MM/YYYY').valueOf() <= moment().valueOf())
    const preorderOrRelease = (isReleased) ? 'Buy ' : 'Pre-Order '

    return <>
      <Box
        w={[ 1 / 1, null, '50%', '48%' ]}
        ml={[0, null, '4vw']} mt={[4, null, 42]} mb={[1, 3, 80]}
      >
        <Artwork onClick={this.play} isClickable={this.props.soundcloudid}>
          { this.props.artwork &&
          <Img
            src={artwork.sizes.src}
            sizes={artwork.sizes}
            alt={artwork.alt}
          />
          }
          <Ctrl left={0}>
            { !this.state.isSoundcloudPlaying && this.state.isSoundcloudReady && <Play /> }
            { this.state.isSoundcloudPlaying && this.state.isSoundcloudReady && <Pause /> }
          </Ctrl>
        </Artwork>
      </Box>
      <Box w={[ 1 / 1, null, '40%', '40%' ]} pt={[0, null, 10, 60]}>
        <ContentTrackListing tracks={this.props.tracks} />
        <Box ml={[0, null, '15%']} mb={'5px'}>
          { this.props.buylink &&
          <BuyLink href={this.props.buylink} target='_blank' rel='noopener noreferrer'>
            <Text f={3} lineHeight={1.4}>{preorderOrRelease} on Bandcamp</Text>
          </BuyLink>
          }
        </Box>
        { this.props.media &&
        <Text color='grey' f={0} mt={10} mb={30} ml={[0, null, '15%']}>{this.props.media.map((media, key) => {
          let type = media.type
          let separator = (this.props.media.length - 1 !== key && this.props.media.length !== 1) ? ' / ' : ''
          return type + separator
        })}</Text>
        }
      </Box>

      { this.props.soundcloudid &&
      <Player>
        <iframe
          title='soundcloud player'
          allow='autoplay'
          ref={this.setIframeRef}
          src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/${this.props.soundcloudid}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=false`}
        />
        <Script
          url='https://w.soundcloud.com/player/api.js'
          onCreate={this.handleScriptCreate.bind(this)}
          onError={this.handleScriptError.bind(this)}
          onLoad={this.handleScriptLoad.bind(this)}
        />
      </Player>
      }
    </>
  }
}

export default ReleaseArtwork
