import styled from 'styled-components'
import { media, easeOutExpo } from '../../theme/'
import { Flex, Box, Link, Absolute } from 'rebass'
import BtnPause from '../../vectors/BtnPause'
import BtnPlay from '../../vectors/BtnPlay'

export const Play = styled(BtnPlay)`
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
`
export const Pause = styled(BtnPause)`
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
`

export const Ctrl = styled(Absolute)`
  transform: translateX(-50%);
  z-index: 1;
`

export const BuyLink = styled(Link)`
  display: inline-block;
  position: relative;
  text-decoration: none;
  color: white;
  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: red;
    opacity: 1;
    transition: transform 0.8s ${easeOutExpo}, opacity 0.8s ${easeOutExpo};
  }
`

export const FlexWrapper = styled(Flex)`
  color: ${props => (props.invert) ? 'black' : 'white'};
  position: relative;
  top: 0;
  z-index: 1;
  ${media.LargeScreenUp`
    position: fixed;
    height: 94vh;
    min-height: 571px;
  `};
  a {
    color: white;
    text-decoration: none;
  }
  h3 {
    color: ${props => (props.invert) ? 'red' : 'white'};
  }
`

export const Player = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(-100%, -100%);
`
export const Artwork = styled(Box)`
  position: relative;
  ${props => props.isClickable ? 'cursor: pointer;' : ''}
  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: 95%;
    height: 95%;
    opacity: 0.6;
    box-shadow: 0 25px 25px 0px rgba(0,0,0,0.52), 0 20px 20px 0 rgba(0,0,0,0.48);
  }
`
