import styled from 'styled-components'
import { Text } from 'rebass'
import { media, typeface } from '../../theme/'

export const Title = styled(Text)`
  font-family: ${typeface.sans};
  font-weight: 700;
  color: white;
`
export const TrackList = styled.ol`
  width: 100%;
  padding: 0;
  list-style: none;
  counter-reset: my-awesome-counter;
  margin-bottom: 25px;
  ${media.LargeScreenUp`
    max-width: 480px;
    margin-left: 15%;
  `}
  li {
    border-bottom: 1px rgba(151, 151, 151, 0.17) dashed;
    counter-increment: my-awesome-counter;
    padding: 10px 28px;
    position: relative;
  }
  li::before {
    position: absolute;
    left: 0;
    content: counter(my-awesome-counter) ". ";
    color: rgba(130, 130, 130, 1);
  }
  li:last-child {
    border-bottom: 0;
  }
`
