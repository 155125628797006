import React from 'react'
import styled, { css } from 'styled-components'

const width = '13'
const height = '17'
const viewBox = '0 0 13 17'

const getDimensions = () => ({
  height,
  width
})

const getDimensionsCss = () => css`
  width: ${width}px;
  height: ${height}px;
`

const Image = styled.svg`
  ${({noStyles}) => !noStyles ? getDimensionsCss() : null}
`

const defaultProps = {
  children: [
    <g
      fill='none'
      fillRule='evenodd'
      stroke='#EDEDED'
      strokeWidth='2'
      key='key-0'
    >
      <path
        d='M12.08 10l-5.609 5.61L1 10.139'
      />
      <path
        strokeLinecap='square'
        d='M6.5 13.5v-12'
      />
    </g>
  ],
  viewBox
}

export default Object.assign(Image, {
  getDimensions,
  getDimensionsCss,
  defaultProps,
  displayName: 'BtnDownload'
})
