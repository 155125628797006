import React from 'react'
import { Flex, Box } from 'rebass'
import { Wrapper, MainHeading, Subheading } from './styles'
import Stripe from '../Stripe'
import Link from '../Link'

const PageContentHolder = (props) => {
  const cleanedHeading = props.heading.replace('&#038;', '&').replace('&#8211;', '&')
  return (
    <Wrapper w={1 / 1} pb={[50, null, 0]}>
      <Flex flexWrap='wrap' px={['5vw']} pt={[10, null, 55]} pb={[30, null, 100]}>
        <Box w={1 / 1} pl={[0, null, '5vw']}>
          <MainHeading is='h1' fontSize={[8, 9, 11]} ml={[0, null, -20]} mt={[10, null, 45]} mb={[10, null, 10]} post={props.post}>
            { cleanedHeading }
          </MainHeading>
          { props.subheading &&
            <Subheading fontSize={0} ml={[0, null, '1.3%']} pt={'5px'}>
              {`Released on ${props.subheading.released} by `}
              <Link to={props.subheading.link}>{props.subheading.by}</Link>
            </Subheading>
          }
          <Stripe heading />
        </Box>
        { props.children }
      </Flex>
    </Wrapper>
  )
}

export default PageContentHolder
