import { Box } from 'rebass'
import { media } from '../../theme/'
import styled from 'styled-components'

export const Content = styled(Box)`
  color: white;
  position: relative;
  z-index: 1;
  margin-top: ${props => props.post ? '2em' : '0'};
  ${props => props.vinline ? 'margin-top:0;' : ''}
  padding-top: ${props => props.post ? '0' : ''};
  
  ${media.LargeScreenUp`
    padding-top: ${props => props.post ? '50px' : ''};
    padding-left: 18%;
  `}
  ${media.GiantScreenUp`
    padding-top: ${props => props.post ? '60px' : ''};
    padding-left: 11.5%;
    max-width: ${props => props.post ? '80%' : ''};
  `}

  & p {
    opacity: 0.7;
    font-size: 14px;
    ${media.LargeScreenUp`
        font-size: 16px;
    `}
    break-inside: avoid;
    line-height: 2.1;
    padding-bottom: 1em;
    max-width: 752px;
  }

  & ${props => props.post ? 'p.intro-para ' : 'p:first-child'}
  {
    opacity: 1;
    font-size: 16px;
    margin-top: 0;
    ${media.LargeScreenUp`
        font-size: 18px;
    `}
    padding-bottom: ${props => props.post ? '2.5em' : '1.5em'};
  }

  
  ${media.GiantScreenUp`
    column-count: ${props => props.post ? '1' : '2'};
    column-fill: balance;
    column-gap: 4rem;
  `}
`
