import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { first, unescape, lowerCase } from 'lodash'
import moment from 'moment'
import Helmet from 'react-helmet'
import { isWindowAvailable } from '../util'
import { black } from '../theme'
import { Heading, Box, Flex } from 'rebass'
import Layout from '../components/Layout'
import Router from '../components/Router'
import Footer from '../components/Footer'
import Stripe from '../components/Stripe'
import PageTitle from '../components/PageTitle'
import ReleaseArtwork from '../components/ReleaseArtwork'
import PageContentHolder from '../components/PageContentHolder'
import ContentReviews from '../components/ContentReviews'
import ContentVideoEmbed from '../components/ContentVideoEmbed'
import ArtistRelease from '../components/ArtistRelease'
import ArticleContent from '../components/ArticleContent'
import DownloadLink from '../components/DownloadLink'
// import MerchandiseListing from '../components/MerchandiseListing'
import styled from 'styled-components'

export const Releases = styled(Box)`
  .gatsby-image-wrapper {
    z-index: 1;
    width: 260px;
    height: auto;
  }
`
export const ArtistImages = styled(Box)`
  position: relative;
  z-index: 1;
  .gatsby-image-wrapper {
    width: 100%;
  }
`

class ReleaseDetailTemplate extends Component {
  render () {
    const siteMetadata = this.props.data.site.siteMetadata
    const currentPage = this.props.data.wordpressPage
    const currentArtistReleases = (this.props.data.allWordpressPage) ? this.props.data.allWordpressPage.edges.filter((r) => { return r.node.title !== currentPage.title }) : []
    const formattedReleaseDate = moment(currentPage.acf.release_date, 'DD/MM/YYYY').format('MMMM D, YYYY')
    const artistLink = `/artists/${lowerCase(first(currentPage.acf.release_artist).post_title).replace(/ /g, '-')}`
    const normalizedListing = currentArtistReleases.map((release) => {
      const image = release.node.acf.release_artwork
      const sizes = (image && image.localFile) ? image.localFile.childImageSharp.sizes : null
      return {
        title: release.node.title,
        attribution: {
          source: first(release.node.acf.release_artist).post_title,
          link: artistLink
        },
        sizes: sizes,
        releasedate: release.node.acf.release_date,
        link: release.node.link
      }
    })
    const bodyStyle = (isWindowAvailable) ? { style: `background-color: ${black.mid};` } : null
    // makes sure if we have no image assign the release artwork
    // const products = (this.props.data.products.acf.release_products) ? this.props.data.products.acf.release_products.map(product => {
    //   if (this.props.data.products.acf.release_artwork) {
    //     product.meta = `${first(currentPage.acf.release_artist).post_title} - ${currentPage.title}`
    //     product.sizes = this.props.data.products.acf.release_artwork.localFile.childImageSharp.sizes
    //   }
    //   return product
    // }) : null

    return (
      <Layout>
        { currentPage && currentPage.acf.seo_page_title &&
          <Helmet title={currentPage.acf.seo_page_title} bodyAttributes={bodyStyle} />
        }
        { currentPage && !currentPage.acf.seo_page_title &&
          <Helmet title={`${siteMetadata.title} - ${currentPage.title}`} bodyAttributes={bodyStyle} />
        }
        <Router
          items={first(this.props.data.router.edges).node.items}
        />
        <PageTitle title='releases' prefix />

        <PageContentHolder
          heading={currentPage.title}
          subheading={
            {
              released: `${formattedReleaseDate}`,
              by: `${first(currentPage.acf.release_artist).post_title}`,
              link: artistLink
            }
          }
        >
          <ReleaseArtwork
            title={currentPage.title}
            artist={first(currentPage.acf.release_artist).post_title}
            releasedate={currentPage.acf.release_date}
            media={currentPage.acf.release_available_media}
            soundcloudid={currentPage.acf.release_audio_preview}
            artwork={currentPage.acf.release_artwork}
            tracks={currentPage.acf.release_track_list}
            buylink={currentPage.acf.release_buy_link}
          />

          {/* { products &&
            <Box w='100%' pl={[0, null, null, '4.5%']}><MerchandiseListing items={products} /></Box>
          } */}
          { currentPage.acf.release_description_long &&
            <ArticleContent>
              <div dangerouslySetInnerHTML={{ __html: unescape(currentPage.acf.release_description_long) }} />
              { currentPage.acf.release_media_kit &&
                <DownloadLink assetlink={currentPage.acf.release_media_kit.source_url}>Download media kit</DownloadLink>
              }
            </ArticleContent>
          }
          { !currentPage.acf.release_description_long && currentPage.acf.release_description_short &&
            <ArticleContent>
              <div dangerouslySetInnerHTML={{ __html: unescape(currentPage.acf.release_description_short) }} />
              { currentPage.acf.release_media_kit &&
              <DownloadLink assetlink={currentPage.acf.release_media_kit.source_url}>Download media kit</DownloadLink>
              }
            </ArticleContent>
          }

          { /* Video */}
          { currentPage.acf.release_video &&
            <ContentVideoEmbed videos={currentPage.acf.release_video} />
          }

          { /* Reviews */}
          { currentPage.acf.release_reviews &&
            <ContentReviews reviews={currentPage.acf.release_reviews} />
          }

          {/* Releases  */}
          { currentArtistReleases.length > 0 && <>
            <Box w={1 / 1} ml={[0, null, '4vw']}>
              <Heading is='h3' fontSize={30} mb={38}>Other releases by { first(currentPage.acf.release_artist).post_title }</Heading>
            </Box>
            <Flex ml={[0, null, '4vw']} mb={'-5rem'} justifyContent='flex-start' flexWrap='wrap'>
              { normalizedListing.map((release, key) => {
                return <ArtistRelease release={release} small key={`car:${key}`} />
              })}
            </Flex>
            </>}

          { currentArtistReleases.length === 0 && <Stripe bottom />}
        </PageContentHolder>
        <Stripe top />

        <Footer />
      </Layout>
    )
  }
}

export default ReleaseDetailTemplate

export const ReleaseDetailQuery = graphql`
  query releaseDetailQuery($id: String!, $artists: String!) {
    router:allWordpressWpApiMenusMenusItems(
      filter: { name: { regex: "/Router/" } },
      sort: { fields: [name], order: DESC}
    ) {
      edges {
        node {
          id
          name
          items {
            title
            url
            type
          }
        }
      }
    }
    products:wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      acf {
        release_artwork {
          
          localFile {
            childImageSharp {

              sizes(
                maxWidth: 800,
                maxHeight: 800
              ) {
                  ...GatsbyImageSharpSizes_withWebp
                }
            }
          }
        }
        release_products {
          name
          price
          weight
          download_guid
        }
      }
    }
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      acf {
        release_id
        release_date
        release_artwork {
          localFile {
            childImageSharp {

              resize {
                src
              }
              sizes(
                maxWidth: 1500,
              ) {
                  ...GatsbyImageSharpSizes_withWebp
                }
            }
          }
        }
        release_available_media {
          type
        }
        release_description_long
        release_description_short
        release_buy_link
        release_audio_preview
        release_track_list {
          title
        }
        release_artist {
          post_title
          post_name
        }
        release_video {
          embed
        }
        release_reviews {
          quote
          quote_quotee
          quote_link
        }
        release_products {
          name
          price
          weight
          download_guid
        }
      }
    }

    allWordpressPage(filter: {acf: {release_artist: {elemMatch: {post_title: {eq: $artists}}}}}) {
      edges {
        node {
          categories {
            name
          }
          title
          link
          acf {
            release_artist {
              post_title
            }
            release_date
            release_id
            release_artwork {
              
                localFile {
                  childImageSharp {
                    sizes(
                      maxWidth: 1500,
                    ) {
                        ...GatsbyImageSharpSizes_withWebp
                      }
                  }
                }
            }
          }
        }
      }
    }
    
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`
