import { Box, Text } from 'rebass'
import Link from '../../components/Link'
import { media, typeface, RouterRollOver, easeOutExpo } from '../../theme/'
import styled from 'styled-components'

export const Release = styled(Box)`
  position: relative;
  text-align: right;
  a {
    text-decoration: none;
  }
  span {
    color: white;
    opacity: 0.4;
    margin-top: 0.5rem;
  }
  .gatsby-image-wrapper {
    z-index: 1;
    width: 90.5vw;
    ${media.LargeScreenUp`
      width: auto;
      min-width: ${props => props.small ? '217px' : '260px'};
    `}
    height: auto;
  }
`

export const ItemLink = styled(Link)`
  text-decoration: none;
  .highlight {
    transition: color 0.3s ${easeOutExpo}, opacity 1 ${easeOutExpo};
  }
  &:hover .highlight {
    color: ${RouterRollOver};
    cursor: pointer;
    opacity: 1;
  }
`

export const Title = styled(Text)`
  font-family: ${typeface.sans};
  font-weight: 700;
  color: white;
`

export const Attribution = styled(Text)`
  font-weight: 700;
  font-style: italic;
  color: rgba(226, 222, 221, 1);
  letter-spacing: 0.05em;
`

export const ReleasedDate = styled(Text)`
  font-style: italic;
  letter-spacing: 0.05em;
  opacity: 0.52;
`
