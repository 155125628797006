import styled from 'styled-components'
import { easeInOutCirc, ctaButtonColor } from '../../theme/'
import BtnDownload from '../../vectors/BtnDownload'

export const DownloadArrow = styled(BtnDownload)`
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
`
export const Link = styled.a`
  display: inline-block;
  position: relative;
  text-decoration: none;
  background-color: ${ctaButtonColor};
  border-radius: 2px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 40px;
  & > div {
    position: relative;
    z-index: 1;
    white-space: nowrap;
  }
  &:hover:before {
    transform: scale(1.03, 1.06);
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: ${ctaButtonColor};
    width: 100%;
    height: 100%;
    border-radius: 2px;
    transition: transform 0.3s ${easeInOutCirc};
    transform: scale(1, 1);
    backface-visibility: hidden;
  }
`
