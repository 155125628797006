import React from 'react'
import { Content } from './styles'
import Stripe from '../Stripe'

const ArticleContent = (props) => {
  return <Content w={[1 / 1]} pt={[50, null, 60]} pb={[0, null, 40]} pr={[0, null, '8%']} post={props.post} vinline={props.vinline}>
      {props.children}
      <Stripe full />
    </Content>
}

export default ArticleContent
