import { Box, Text } from 'rebass'
import { media, typeface, easeOutExpo } from '../../theme/'
import styled from 'styled-components'

export const Wrapper = styled(Box)`
  color: white;
  min-height: 73.5vh;
  position: relative;
  z-index: 1;
`
export const MainHeading = styled(Text)`
  position: relative;
  font-weight: bold;
  font-family: ${typeface.sans};
  line-height: 1.1;
  letter-spacing: 0.062em;
  z-index: 1;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;

  ${media.GiantScreenUp`
    max-width: ${props => props.post ? '80%' : ''};
  `}
`
export const Subheading = styled(Text)`
  position: relative;
  font-weight: bold;
  font-family: ${typeface.sans};
  line-height: 1.1;
  letter-spacing: 0.062em;
  z-index: 1;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  a {
    text-decoration: none;
    opacity: 0.6;
    font-style: italic;
    transition: opacity 0.5s ${easeOutExpo};
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
`
