import React from 'react'
import styled from 'styled-components'
import { Box } from 'rebass'

export const VideoWrapper = styled(Box)`
  position: relative;
  z-index: 2 !important;
  padding-bottom: 56.25%;
  background: black;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
const ContentVideoEmbed = (props) => {
  return (
    <Box w={1 / 1} pb={[0, null, 0]} my={[0, null, 2]}>
      { props.videos.map((video, key) => {
        const embedcode = video.embed.replace(`width="640" height="360"`, ``)
        return <VideoWrapper ml={[0, null, '3vw']} my={[25, null, 47]} width={[1 / 1, null, 15 / 16]} key={`rv:${key}`} dangerouslySetInnerHTML={{ __html: unescape(embedcode) }} />
      })}
    </Box>
  )
}

export default ContentVideoEmbed
