import React from 'react'
import { TrackList } from './styles'
const ContentTrackListing = (props) => {
  return (

    <TrackList>
      {
        props.tracks && props.tracks.map((item, key) => {
          const cleanedTitle = item.title.replace('&#038;', '&').replace('&#8211;', '&')
          const uid = `${item.title}:${key}`
          const title = <li f={[2]} key={uid}>{ cleanedTitle }</li>
          return (
            item.title && title
          )
        })
      }
    </TrackList>
  )
}

export default ContentTrackListing
