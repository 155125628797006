import React from 'react'
import { Text } from 'rebass'
import { Link, DownloadArrow } from './styles'

const DownloadLink = ({ children, assetlink, ...other }) => {
  return (
    <Link href={assetlink} {...other} className='uppercase'>
      <DownloadArrow />
      <Text
        fontSize={0}
        pl={46} pr={28} py={14}
      >{children}</Text>
    </Link>
  )
}

export default DownloadLink
