import styled from 'styled-components'
import { Box, Text } from 'rebass'
import { media, typeface } from '../../theme/'

export const Wrapper = styled(Box)`
  position: relative;
`

export const QuoteWrapper = styled(Box)`
  position: relative;
  z-index: 1;
  max-width: 870px;
  transform: translateX(${props => (props.reverse) ? '0%' : '30%'});

  &:after {
    content:'';
    position: absolute;
    z-index: 0;
    bottom: 5px;
    left: 0;
    right: 0;
    width: 90%;
    height: 90%;
    margin: 0 auto;
    box-shadow: 0 0 80px 1px rgba(0, 0, 0, 0.8), 0 0 50px 1px rgba(0,0,0, 0.6);
    background-color: orange;
  }
`

export const Quote = styled(Box)`
  position: relative;
  z-index: 1;
  color: ${props => (props.reverse) ? 'white' : '#1E1E1E'};
  background-color: ${props => (props.reverse) ? '#1E1E1E' : '#F1F1F1'};
  p {
    opacity: 0.67;
    font-style: italic;
  }
  
`

export const QuoteCopy = styled(Box)`
  position: relative;
  &:before, &:after {
    position: absolute;
    font-family: ${typeface.serif};
    font-weight: 400;
    font-size: 144px;
    color: ${props => (props.reverse) ? 'white' : '#1E1E1E'};
    line-height: 120px;
    display: block;
    width: 35px;
    height: 35px;
  }
  &:before {
    content: '“';
    top: 0;
    left: 0;
    transform: translateX(-150%) translateY(-70%) scale(0.8);
    ${media.MediumScreenUp`
      transform: translateX(-220%) translateY(-70%) scale(0.9);
    `}
    ${media.LargeScreenUp`
      transform: translateX(-200%) translateY(-70%);
    `}
  }
  &:after {
    content: '”';
    bottom: 0;
    right: 0;
    transform: translateX(150%) translateY(30%) scale(0.8);
    ${media.MediumScreenUp`
      transform: translateX(220%) translateY(30%) scale(0.9);
    `}
    ${media.LargeScreenUp`
      transform: translateX(100%) translateY(30%);
    `}
  }
`

export const Quotee = styled(Text)`
  font-family: ${typeface.sans};
  font-weight: 400;
  font-size: 18px;
  
  a {
    text-decoration: none;
    color: ${props => (props.reverse) ? 'white' : '#1E1E1E'} !important;
  }
`
