import React from 'react'
import { Heading } from 'rebass'
import { Quote, Quotee, QuoteCopy, QuoteWrapper, Wrapper } from './styles'
import Link from '../../components/Link'
import Stripe from '../Stripe'
const ContentTrackListing = (props) => {
  return (
    <Wrapper
      w={1 / 1}
      pb={[80, null, 135]} mb={[0]}
    >

      <Stripe full />
      <Heading
        is='h3' fontSize={[7, null, 8]}
        mt={[30, null, 50]} mb={[20, null, 50]}
        ml={[0, null, '11vw']}
      >
      Reviews
      </Heading>

      { props.reviews && props.reviews.map((review, key) => {
        const isReverse = (key + 1) % 2
        return (
          <QuoteWrapper
            ml={[0, null, '11vw']}
            reverse={isReverse}
            key={`rv:${review.quote_quotee}:${key}`}

          >
            <Quote
              pl={['10%', null, '20%']} pr={['10%', null, '15%']} pt={[30, null, 80]} pb={[40, null, 100]} mb={-35}
              reverse={isReverse}
            >
              <QuoteCopy
                reverse={isReverse}
                dangerouslySetInnerHTML={{ __html: unescape(review.quote) }} />
              <div>
                { review.quote_link &&
                  <Quotee reverse={isReverse}><Link to={review.quote_link}>{review.quote_quotee}</Link></Quotee>
                }
                { !review.quote_link &&
                  <Quotee reverse={isReverse}>{review.quote_quotee}</Quotee>
                }
              </div>
            </Quote>
          </QuoteWrapper>
        )
      })}
    </Wrapper>
  )
}

export default ContentTrackListing
